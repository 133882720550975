import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import React, { Component } from "react";
import "../style/contact.scss";

class ContacUs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="contact-page">
          <div className="title-head">Contact</div>
          <div className="contact-content">
            <p>If you have any questions please call us:</p>
            {/* email */}
            <p>Free Email at tree@oxyern.live</p>
            <p>Email us using the form below, thanks.</p>
            <div className="row">
              <div className="title">Name</div>
              <input type="text" />
            </div>
            <div className="row">
              <div className="title">Email</div>
              <input type="text" />
            </div>
            <div className="row">
              <div className="title">Phone</div>
              <input type="text" />
            </div>
            <div className="row">
              <div className="title">Comments/Questions</div>
              <textarea></textarea>
            </div>
            <div className="row">
              <div className="btn">Submit</div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ContacUs;
