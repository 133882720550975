import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/about.scss";
import logo_black from "../image/logo-black.png";

class About extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="about-page">
          <div className="title-head">About</div>
          <div className="about-contain">
            <div className="about-tent">
              <div className="img-box">
                <img src={logo_black} alt="" />
              </div>
              <p>
                After years of operating as a local brick and mortar cat
                boutique, and in light of the growing success of our website,
                decided in 2003 to dedicate itself to serving a broader cat
                community. Today, our ecommerce business, site thrives as a
                specialty cat-focused ecommerce store, cultivating a cat loving
                community around the world!
              </p>
              <p>
                site specializes in providing ONLY THE BEST quality products and
                solutions for the most loving and discerning cat owner. The
                driving force behind site is our lifelong love of cats and a
                passionate commitment to their welfare and responsible care. We
                carefully curate our product offering so that you won't have to
                waste time on sites that "offer everything", including products
                that are of inferior quality and lack value. You'll find
                complete product descriptions and reviews that give you the
                information to make a confident purchase you can trust, at very
                AFFORDABLE PRICES and SHIPPING RATES. Don't miss our On Sale
                items and our Special Offer of the Month.
              </p>
              <p>
                {" "}
                As cat lovers like you know, cats are choosy. While each has
                his/her own distinctive purr-sonality and preferences (like us)
                we find our products attract even the most finicky cats! Simply,
                they use them and therefore, benefit from them. Read our
                customer testimonials to see for yourself.{" "}
              </p>
              <p>
                strives to educate and advance the principles of humane and
                compassionate pet care, as well as the responsible stewardship
                involved with all of God’s creatures and their environment. We
                have shared our expertise and enthusiasm on television and
                radio, and have been published in widely nationally recognized
                cat focused blogs and sites.{" "}
              </p>
              <p>
                What’s most rewarding to site is that it gives us an ideal
                platform to support animal rescue and shelter organizations when
                possible and to serve as volunteers in our local area. We
                encourage everyone to give a shelter pet a loving, forever home.
              </p>
              <p>
                {/* email */}
                Please feel free to contact us , or contact us email tree@oxyern.live.
              </p>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default About;
